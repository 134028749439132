import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, OAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const appleSignIn = async () => {
    const provider = new OAuthProvider('apple.com');
    try {
        const result = await signInWithPopup(auth, provider);
        console.log("Apple User:", result.user);
        return result; // Return the result to ensure the promise resolves properly
    } catch (error) {
        console.error("Error during Apple sign-in:", error);
        throw error; // Re-throw the error so it can be caught by the caller
    }
};

const googleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
        const result = await signInWithPopup(auth, provider);
        console.log("Google User:", result.user);
        return result; // Return the result to ensure the promise resolves properly
    } catch (error) {
        console.error("Error during Google sign-in:", error);
        throw error; // Re-throw the error so it can be caught by the caller
    }
};

export { auth, appleSignIn, googleSignIn };

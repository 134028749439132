// src/DeleteAccount.js
import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { deleteUser } from 'firebase/auth';
import './DeleteAccount.css'; // Importing the CSS styles
import ConfirmationModal from './ConfirmationModal'; // Import the modal component

const DeleteAccount = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleDeleteAccount = async () => {
    try {
      if (user) {
        // Get the Firebase ID token
        const idToken = await user.getIdToken();
        const API_URL = process.env.REACT_APP_API_URL;
        // Send the request to your backend API to delete the user
        const response = await axios.delete(API_URL+'/users/delete', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        if (response.status === 200) {
          alert('Your account has been deleted.');
          navigate('/'); // Redirect to the homepage or login page
        } else {
          alert('Error occurred while deleting your account. Please try again later.');
        }
      }
    } catch (err) {
      console.error('Error deleting account:', err);
      alert('Error occurred while deleting your account. Please try again later.');
    }
  };

  // Show confirmation modal
  const confirmDelete = () => {
    setShowModal(true);
  };

  // Handle modal actions
  const handleConfirm = () => {
    setShowModal(false);
    handleDeleteAccount(); // Proceed with the deletion
  };

  const handleCancel = () => {
    setShowModal(false); // Close the modal without doing anything
  };

  return (
    <div className="delete-account-container">
      {/* Company Logo */}
      <img src="logo.png" alt="Company Logo" className="delete-account-logo" />

      <h2 className="delete-account-header">Delete Your Account</h2>
      <p className="delete-account-text">
        Are you sure you want to delete your account? This action cannot be undone.
      </p>
      <button className="delete-account-button" onClick={confirmDelete}>
        Delete Account
      </button>

      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showModal}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default DeleteAccount;

// src/Login.js
import React from 'react';
import { googleSignIn, appleSignIn } from './firebaseConfig';
import { useNavigate } from 'react-router-dom';
import './Login.css';  // Importing the CSS styles

const Login = () => {
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    try {
      const result = await googleSignIn();
      if (result.user) navigate('/delete');
    } catch (error) {
      console.error("Google login failed:", error);
    }
  };

  const handleAppleLogin = async () => {
    try {
      const result = await appleSignIn();
      if (result.user) navigate('/delete');
    } catch (error) {
      console.error("Apple login failed:", error);
    }
  };

  return (
    <div className="login-container">
      {/* Your Company Logo */}
      <img src="logo.png" alt="Company Logo" className="login-logo" />

      <h2 className="login-header">Delete Account<br /><span style={{ color: 'grey' }}>Please Login First</span></h2>

      {/* Google and Apple login buttons */}
      <button className="login-button" onClick={handleGoogleLogin}>
        Login with Google
      </button>
      <button className="login-button apple" onClick={handleAppleLogin}>
        Login with Apple
      </button>
      v3.5
    </div>
  );
};

export default Login;
